import { useState, useEffect } from "react";
import Loandetails from "./Loandetails";
import StateandLGA from "./StateandLGA";
import { IoArrowBackCircleOutline, IoCheckmarkDoneSharp } from "react-icons/io5";
import axios from "axios";

const Mainform=({parastatal_form})=>{
    const [formpart, setFormpart] = useState(1);
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [othername, setOthername] = useState("");
    const [address, setAddress] = useState("");
    const [dob, setDOB] = useState("");
    const [bvn, setBVN] = useState("");
    const [nin, setNIN] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [kinName, setKinName] = useState("");
    const [kinPhone, setKinPhone] = useState("");
    const [kinRel, setKinRel] = useState("");
    const [kinAddress, setKinAddress] = useState("");
    const [nearestlandmark, setNearestlandmark] = useState("");
    const [alltext, setAllText] = useState("");
    const [dropdownvalues, setDropdownvalues] = useState(new Array(10).fill(""));
    let myArray = new Array(10);
    //children state
    const [parastatal, setParastatal] = useState("");
    const [department, setDepartment] = useState("");
    const [oracle, setOracle] = useState("");
    const [employmentdate, setEmploymentdate] = useState("");
    const [retirementdate, setRetirementdate] = useState("");
    const [loanpurpose, setLoanpurpose] = useState("");
    const [loanamount, setLoanamount] = useState("");
    const [loanamountwords, setLoanamountwords] = useState("");
    const [netpay, setNetpay] = useState("");
    const [grosspay, setGrosspay] = useState("");
    const [accountname, setAccountname] = useState("");
    const [accountnumber, setAccountnumber] = useState("");
    const [accountofficername, setAccountofficername] = useState("");
    const [accountofficeremail, setAccountofficeremail] = useState("");
    const [passportURL, setPassportURL] = useState(null);
    const [idImageURL, setIdImageURL] = useState(null);
    const [payslipURL, setPayslipURL] = useState(null);
    const [letterURL, setLetterURL] = useState(null);
    const [file_ext1, setFile_ext1] = useState("") ;
    const [file_ext2, setFile_ext2] = useState("");

    const handlePassportUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setPassportURL(reader.result);
        };
        reader.readAsDataURL(file);
    };
    const handleIdImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setIdImageURL(reader.result);
        };
        reader.readAsDataURL(file);
    };
    const handlePayslipUpload = (event) => {
        const file = event.target.files[0];
        setFile_ext1 (file.name.split('.').pop().toLowerCase());
        console.log(`payslip.${file_ext1}`)
        const reader = new FileReader();
            reader.onload = function(event) {
                const base64String = event.target.result.split(',')[1]; // Strip out metadata
                setPayslipURL(base64String)
            };
            reader.onerror = function(error) {
                console.log('Error: ', error);
            };
            reader.readAsDataURL(file); // Converts the file to a base64-encoded string
    };
    const handleLetterUpload = (event) => {
        const file = event.target.files[0];
        setFile_ext2(file.name.split('.').pop().toLowerCase());
        const reader = new FileReader();
            reader.onload = function(event) {
                const base64String = event.target.result.split(',')[1]; // Strip out metadata
                setLetterURL(base64String)
            };
            reader.onerror = function(error) {
                console.log('Error: ', error);
            };
            reader.readAsDataURL(file); // Converts the file to a base64-encoded string
    };
    const setValue=(value,index)=>{
        setDropdownvalues(prevArray => {
            const newArray = [...prevArray]; // Create a copy of the current array
            newArray[index] = value;         // Update the specific index
            return newArray; 
        });
        myArray[index] = value;
    }
    const setInfo =()=> {
        setAllText(`
        <p><b> ${parastatal_form}</b></p>
        <p><b>Personal Information</b></p>
        <p><b>First Name:</b> ${firstname}</p>
        <p><b>Last Name:</b> ${lastname}</p>
        <p><b>Other Names:</b> ${othername}</p>
        <p><b>Date of birth:</b> ${dob}</p>
        <p><b>Gender:</b> ${dropdownvalues[0], myArray[0],document.querySelector('select[name="gender"]')?.value}</p>
        <p><b>Marital Status:</b> ${dropdownvalues[1], myArray[1],document.querySelector('select[name="Marital-Status"]')?.value}</p>
        <p><b>Phone:</b> ${phone}</p>
        <p><b>Email:</b> ${email}</p>
        <p><b>Means of Identification:</b> ${dropdownvalues[2], myArray[2], document.querySelector('select[name="id-means"]')?.value}</p>
        <p><b>BVN:</b> ${bvn}</p>
        <p><b>NIN:</b> ${nin}</p>
        <p><b>Address:</b> ${address}</p>
        <p><b>Nearest Landmark:</b> ${nearestlandmark}</p>
        <p><b>State:</b> ${dropdownvalues[3],document.querySelector('select[name="State"]')?.value}</p>
        <p><b>LGA</b> ${dropdownvalues[4],document.querySelector('select[name="LGA"]')?.value}</p>
        <p><b>Kin FullName:</b> ${kinName}</p>
        <p><b>Kin Phone Number:</b> ${kinPhone}</p>
        <p><b>Kin Relationship:</b> ${kinRel}</p>
        <p><b>Kin Address:</b> ${kinAddress}</p>
        <p><b>Load Details</b></p>
        <p><b>Parastatal:</b> ${parastatal}</p>
        <p><b>Department:</b> ${department}</p>
        <p><b>Oracle:</b> ${oracle}</p>
        <p><b>Employment date:</b> ${employmentdate}</p>
        <p><b>Retirement date:</b> ${retirementdate}</p>
        <p><b>Loan purpose:</b> ${loanpurpose}</p>
        <p><b>Loan Tenure:</b> ${dropdownvalues[5],document.querySelector('select[name="tenure"]')?.value}</p>
        <p><b>Loan amount:</b> ${loanamount}</p>
        <p><b>Loan amount in words:</b> ${loanamountwords}</p>
        <p><b>Net pay:</b> ${netpay}</p>
        <p><b>Gross pay:</b> ${grosspay}</p>
        <p><b>Bank:</b> ${dropdownvalues[6],document.querySelector('select[name="banklist"]')?.value}</p>
        <p><b>Account number:</b> ${accountnumber}</p>
        <p><b>Account name:</b> ${accountname}</p>
        <p><b>Account officer name:</b> ${accountofficername}</p>
        <p><b>Account officer email:</b> ${accountofficeremail}</p>
        <p><b>Declaration Signed:</b> Yes</p> 
        `)
    }

    useEffect(()=>{
        setInfo();
    })

    const  sendEmailWithAttachment = async (event) => {
    event.preventDefault();
    try {
        const response = await axios.post(
            'https://api.brevo.com/v3/smtp/email',
            {
                sender: {
                    name: 'Unilag Microfinance Bank',
                    email: 'davidezeani11@gmail.com'
                },
                to: [
                    {
                        email: 'amuritala@unilagmfbank.com',//davidezeani11@gmail.com
                        name: 'Dave'//Abimbola Muritala
                    }
                ],
                subject: 'New Public Sector Loan Application',
                htmlContent: alltext,
                attachment: [
                    {   //base64 encoded images selecting just the actual base64 string
                        content: passportURL?.split(",")[1],
                        name: 'passport.jpg'
                    },
                    {     
                        content: idImageURL?.split(",")[1],
                        name: 'idDocument.jpg'
                    },
                    {
                        content: payslipURL,
                        name: `payslip.${file_ext1}`
                    },
                    {
                        content: letterURL,
                        name: `letter.${file_ext2}`
                    },       
                ]
            },
            {
                headers: {
                    'accept': 'application/json',
                    'api-key': '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',
                    'content-type': 'application/json'
                }
            }
        );
        console.log('Email sent', response.data); 
        alert("Registration Successful!")
    } catch (error) {
        console.error('Error sending email:', error);
    }
};

    return(
        <div>
            <div className="form-body">
                <center>
                <div className="progressgraph">
                    {formpart === 1?<></>:<span onClick={()=>setFormpart(1)} className="arrow"><IoArrowBackCircleOutline/></span>}
                    {formpart === 1?<div className="circle-active"><div className="inner-circle"></div></div>:<div className="circle-completed"><IoCheckmarkDoneSharp/></div>}
                    {formpart === 1?<div className="edge"></div>:<div className="edge-active"></div>}
                    {formpart === 1?<div className="circle-inactive"></div>:<div className="circle-active"><div className="inner-circle"></div></div>}
                    <span>.</span>
                </div>
                </center>
                <div>
                    <form onSubmit={sendEmailWithAttachment} method="POST">
                    {formpart === 1? 
                    <div className="form-wrap" align="left">
                        <div className="mini-head">Personal Information</div>
                        <label><p className="note">Fields marked with asteriks (<span  className="red-asteriks">*</span>) are required</p></label>
                        <div className="item-container">
                            <label for="firstname">First Name <span  className="red-asteriks">*</span></label><br/>
                            <input type="text" name="firstname" id="firstname" className="entries" value={firstname} onChange={event =>setFirstname(event.target.value)} placeholder="Enter first name" required/>
                        </div>
                        <div className="item-container">
                            <label for="lastname">Last Name <span  className="red-asteriks">*</span></label><br/>
                            <input type="text" name="lastname" id="lastname" className="entries" value={lastname} onChange={event =>setLastname(event.target.value)} placeholder="Enter Lastname" required/>
                        </div>
                        <div className="item-container">
                            <label for="othername">Other Names <span  className="red-asteriks">*</span></label><br/>
                            <input type="text" name="othername" id="othername" className="entries" value={othername} onChange={event =>setOthername(event.target.value)} placeholder="Enter  other names" required/>
                        </div>
                        <div className="item-container">
                            <label for="dob">Date of Birth <span  className="red-asteriks">*</span></label><br/>
                            <input type="date" name="dob" id="dob" className="entries" value={dob} onChange={event =>setDOB(event.target.value)} required/>
                        </div>
                        <div className="item-container">
                            <label for="gender">Gender <span  className="red-asteriks">*</span></label><br/>
                            <select name="gender" className="list" onChange={(event) =>setValue(event.target.value,0)} required>
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Others">Others</option>
                            </select>
                        </div>
                        <div className="item-container">
                            <label for="Marital Status">Marital Status <span  className="red-asteriks">*</span></label><br/>
                            <select name="Marital-Status" className="list" onChange={(event) =>setValue(event.target.value,1)} required>
                                <option value="">Select Marital Status</option>
                                <option value="Single">Single</option>
                                <option value="Married">Married</option>
                                <option value="Divorced">Divorced</option>
                                <option value="Widow">Widow</option>
                                <option value="Widower">Widower</option>
                            </select>
                        </div>
                        <div className="item-container">
                            <label for="phone">Phone <span className="red-asteriks">*</span></label><br/>
                            <input type="number" name="phone" id="phone" className="entries" placeholder="Phone Number" value={phone} onChange={event =>setPhone(event.target.value)} required/>
                        </div>
                        <div className="item-container">
                            <label for="email">Email <span className="red-asteriks">*</span></label><br/>
                            <input type="email" name="email" id="email" className="entries" placeholder="Enter Email" value={email} onChange={event =>setEmail(event.target.value)} required/>
                        </div>
                        <div className="item-container">                
                            <label for="id-means">Means of Identification<span className="red-asteriks">*</span></label><br/>
                            <select name="id-means" className=" list" onChange={(event) =>setValue(event.target.value,2)} required>
                                <option value="">Means of Identification</option>
                                <option value="Driver's License">Driver's License</option>
                                <option value="National Identity Card">National Identity Card</option>
                                <option value="Passport">Passport</option>
                                <option value="Voter's Card">Voter's Card</option>
                                <option value="Others">Others</option>
                            </select><br/>
                        </div>
                        <div className="item-container">
                            <label for="bvn">Bank Verification Number (BVN) <span className="red-asteriks">*</span></label><br/>
                            <input type="text" name="bvn" id="bvn" className="entries" placeholder="Enter BVN" value={bvn} onChange={event =>setBVN(event.target.value)} required/>
                        </div>
                        <div className="item-container">
                            <label for="nin">National ID Number (NIN) <span className="red-asteriks">*</span></label><br/>
                            <input type="text" name="nin" id="nin" className="entries" placeholder="Enter NIN" value={nin} onChange={event =>setNIN(event.target.value)} required/>
                        </div>
                        <div className="item-container">
                            <label for="address">Address <span  className="red-asteriks">*</span></label><br/>
                            <input type="text" name="address" id="address" className="entries long" placeholder="Enter Address" value={address} onChange={event =>setAddress(event.target.value)} required/>
                        </div>
                        <div className="item-container">
                            <label for="nearestlandmark">Nearest Landmark<span className="red-asteriks">*</span></label><br/>
                            <input type="text" name="nearestlandmark" id="nearestlandmark" className="entries" placeholder="Enter Nearest Landmark" value={nearestlandmark} onChange={event =>setNearestlandmark(event.target.value)} required/>
                        </div>
                        <StateandLGA {...{setValue}}/>
                        <div className="mini-head">Next of Kin Information</div>
                        <div className="item-container">
                            <label for="kin-name">Full Name <span  className="red-asteriks">*</span></label><br/>
                            <input type="text" name="kin-name" id="kin-name" className="entries" value={kinName} onChange={event =>setKinName(event.target.value)} placeholder="Enter Full Name" required/>
                        </div>
                        <div className="item-container"> 
                            <label for="kin-phone">Phone <span className="red-asteriks">*</span></label><br/>
                            <input type="number" name="kin-phone" id="kin-phone" className="entries" value={kinPhone} onChange={event =>setKinPhone(event.target.value)} placeholder="Enter Phone Number" required/>
                        </div>
                        <div className="item-container"> 
                            <label for="kin-relationship">Relationship <span className="red-asteriks">*</span></label><br/>
                            <input type="text" name="kin-relationship" id="kin-relationship" className="entries" value={kinRel} onChange={event =>setKinRel(event.target.value)} placeholder="Relationship" required/>
                        </div>
                        <div className="item-container"> 
                            <label for="kin-address">Address </label><br/>
                            <input type="text" name="kin-address" id="kin-address" className="entries long" placeholder="Enter Residential Address" value={kinAddress} onChange={event =>setKinAddress(event.target.value)} />
                        </div>
                        <div className="button-cont"><input type="button" className="btn" onClick={()=>{setFormpart(2);window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}} value={"Next"}/></div>
                    </div>
                    :
                    <div className="form-wrap" align="left">
                        <div className="mini-head">Loan Details</div>
                        <Loandetails  {...{parastatal, department,oracle,
                                            employmentdate,retirementdate,
                                            loanpurpose,loanamount, netpay,
                                            loanamountwords, grosspay,accountname, 
                                            accountnumber,accountofficername,
                                            accountofficeremail, setParastatal, 
                                            setDepartment, setOracle, 
                                            setEmploymentdate, setRetirementdate, 
                                            setLoanpurpose, setLoanamount, setLoanamountwords, 
                                            setNetpay, setGrosspay, setAccountname , 
                                            setAccountnumber, setAccountofficername, 
                                            setAccountofficeremail, handlePassportUpload,
                                            handleIdImageUpload,handlePayslipUpload,
                                            handleLetterUpload, setValue}}/>
                    </div>
                    } 
                    {formpart === 1? <></>:<div className="button-cont">
                        <input type="button" className="btn" onClick={()=>setFormpart(1)} value={"Previous"}/>
                        <input type="submit" className="btn" name="submit" id="submit" value={"Submit"} />
                        </div>}
                </form>
                </div>
            </div>
        </div>
    );
}

export default Mainform;