import UMFB_Logo from "../UMFB-LOGO-MAIN.png";
import Mainform from "./Mainform";

const Immigration=()=>{
    var parastatal_form = "NIGERIA IMMIGRATION SERVICE";
    return(
        <div>
        <center>
        <div className="head-cont">
            <img src={UMFB_Logo} id="UMFB-Logo"/>
            <h2>NIGERIA IMMIGRATION SERVICE</h2>
        </div>
        <Mainform  {...{parastatal_form}}/>
        </center>
        </div>
    );
}

export default Immigration;