import { useState } from "react";

const Loandetails=({parastatal, department,oracle,
                    employmentdate,retirementdate,
                    loanpurpose,loanamount, netpay,
                    loanamountwords, grosspay,accountname, 
                    accountnumber,accountofficername,
                    accountofficeremail,setParastatal, 
                    setDepartment, setOracle, 
                    setEmploymentdate, setRetirementdate, 
                    setLoanpurpose, setLoanamount, setLoanamountwords, 
                    setNetpay, setGrosspay, setAccountname , 
                    setAccountnumber, setAccountofficername, 
                    setAccountofficeremail, handlePassportUpload,
                    handleIdImageUpload,handlePayslipUpload,
                    handleLetterUpload,setValue})=>{


    return(
        <div> 
            <div className="item-container"> 
                <label for="parastatal">Ministry/Parastatals<span className="red-asteriks">*</span></label><br/>
                <input type="text" name="parastatal" id="parastatal" className="entries" placeholder="Enter Parastatal " value={parastatal} onChange={event =>setParastatal(event.target.value)} required/>
            </div>
            <div className="item-container"> 
                <label for="Department">Department<span className="red-asteriks">*</span></label><br/>
                <input type="text" name="Department" id="Department" className="entries" placeholder="Enter Department" value={department} onChange={event =>setDepartment(event.target.value)} required/>
            </div>
            <div className="item-container"> 
                <label for="oracle">Oracle<span className="red-asteriks">*</span></label><br/>
                <input type="text" name="oracle" id="oracle" className="entries" placeholder="Oracle" value={oracle} onChange={event =>setOracle(event.target.value)} required/>
            </div>
            <div className="item-container"> 
                <label for="employmentdate">Date of Employment<span className="red-asteriks">*</span></label><br/>
                <input type="date" name="employmentdate" id="employmentdate" className="entries" placeholder="Enter Employment Date" value={employmentdate} onChange={event =>setEmploymentdate(event.target.value)} required/>
            </div>
            <div className="item-container"> 
                <label for="retirementdate">Date of Retirement<span className="red-asteriks">*</span></label><br/>
                <input type="date" name="retirementdate" id="retirementdate" className="entries" placeholder="Enter Retirement Date" value={retirementdate} onChange={event =>setRetirementdate(event.target.value)} required/>
            </div>
            <div className="mini-head">Loan</div>
            <div className="item-container"> 
                <label for="loanpurpose">Purpose of Loan<span className="red-asteriks">*</span></label><br/>
                <input type="text" name="loanpurpose" id="loanpurpose" className="entries" placeholder="What is the Purpose of this Loan?" value={loanpurpose} onChange={event =>setLoanpurpose(event.target.value)} required/>
            </div>
            <div className="item-container">                
                <label for="tenure">Select Loan Tenure<span className="red-asteriks">*</span></label><br/>
                <select name="tenure" className=" list" onChange={(event) =>setValue(event.target.value,5)} required>
                    <option value="">Loan Tenure</option>
                    <option value="3 Months">3 Months</option>
                    <option value="6 Months">6 Months</option>
                    <option value="9 Months">9 Months</option>
                    <option value="12 Months">12 Months</option>
                    <option value="15 Months">15 Months</option>
                    <option value="18 Months">18 Months</option>
                </select><br/>
            </div>
            <div className="item-container"> 
                <label for="loanamount">Amount Requested<span className="red-asteriks">*</span></label><br/>
                <input type="number" name="loanamount" id="loanamount" className="entries" placeholder="Loan Amount" value={loanamount} onChange={event =>setLoanamount(event.target.value)} required/>
            </div>
            <div className="item-container">
                <label for="loanamountwords">Loan amount in words<span className="red-asteriks">*</span></label><br/>
                <input type="text" name="loanamountwords" id="loanamountwords" className="entries long" placeholder="Loan amount in Words" value={loanamountwords} onChange={event =>setLoanamountwords(event.target.value)} required/>
            </div>
            <div className="item-container">
                <label for="netpay">Monthly Net Pay<span className="red-asteriks">*</span></label><br/>
                <input type="text" name="netpay" id="netpay" className="entries" placeholder="Monthly Net Pay" value={netpay} onChange={event =>setNetpay(event.target.value)} required/>
            </div>
            <div className="item-container">
                <label for="grosspay">Monthly Gross Pay<span className="red-asteriks">*</span></label><br/>
                <input type="text" name="grosspay" id="grosspay" className="entries" placeholder="Monthly Gross Pay" value={grosspay} onChange={event =>setGrosspay(event.target.value)} required/>
            </div>
            <div className="mini-head">Disbursement</div>
            <div className="item-container">                
                <label for="banklist">Select Bank<span className="red-asteriks">*</span></label><br/>
                <select name="banklist" className=" list" onChange={(event) =>setValue(event.target.value,6)} required>
                    <option value="">Select Bank</option>
                    <option value="Access Bank">Access Bank</option>
                    <option value="Citibank">Citibank</option>
                    <option value="Eco Bank">Eco Bank</option>
                    <option value="Fidelity Bank">Fidelity Bank</option>
                    <option value="First Bank">First Bank</option>
                    <option value="First City Monument Bank (FCMB)">First City Monument Bank (FCMB)</option>
                    <option value="Guaranteed Trust Bank (GTB)">Guaranteed Trust Bank (GTB)</option>
                    <option value="Heritage Bank">Heritage Bank</option>
                    <option value="Keystone Bank">Keystone Bank</option>
                    <option value="Polaris Bank">Polaris Bank</option>
                    <option value="Providus Bank">Providus Bank</option>
                    <option value="Stanbic IBTC Bank">Stanbic IBTC Bank</option>
                    <option value="Standard Chatered Bank">Standard Chatered Bank</option>
                    <option value="Sterlin Bank">Sterlin Bank</option>
                    <option value="Suntrust Bank">Suntrust Bank</option>
                    <option value="Union Bank">Union Bank</option>
                    <option value="UBA">UBA</option>
                    <option value="Unity Bank">Unity Bank</option>
                    <option value="Wema Bank">Wema Bank</option>
                    <option value="Zenith Bank">Zenith Bank</option>
                </select><br/>
            </div>
            <div className="item-container">
                <label for="accountnumber">Account Number<span className="red-asteriks">*</span></label><br/>
                <input type="number" name="accountnumber" id="accountnumber" className="entries" placeholder="Enter Account Number" value={accountnumber} onChange={event =>setAccountnumber(event.target.value)} required/>
            </div>
            <div className="item-container">
                <label for="accountname">Account Name<span className="red-asteriks">*</span></label><br/>
                <input type="text" name="accountname" id="accountname" className="entries" placeholder="Enter Account Name" value={accountname} onChange={event =>setAccountname(event.target.value)} required/>
            </div>
            <div className="item-container">
                <label for="accountofficername">Account Officer Name<span className="red-asteriks">*</span></label><br/>
                <input type="text" name="accountofficername" id="accountofficername" className="entries" placeholder="Enter Account Officer Name" value={accountofficername} onChange={event =>setAccountofficername(event.target.value)} required/>
            </div>
            <div className="item-container">
                <label for="accountofficeremail">Account Officer Email<span className="red-asteriks">*</span></label><br/>
                <input type="text" name="accountofficeremail" id="accountofficeremail" className="entries" placeholder="Enter Account Officer Email" value={accountofficeremail} onChange={event =>setAccountofficeremail(event.target.value)} required/>
            </div>
            <div className="mini-head">Required Files</div>
            <div className="requirements">
                <p>1. Passport photograph.</p>
                <p>2. Institution/Work Id card.</p>
                <p>3. Recent Payslip or Bank statement from Last 3 Months including Salary Payment.</p>
                <p>4. Letter of Employment/Confirmation/Promotion.</p>     
            </div>
            <div className="item-container">
                <label for="Passport">Upload Passport Photograph<span className="red-asteriks">*</span></label><br/>
                <input type="file" accept="image/*" name="Passport" id="Passport" className="entries" onChange={handlePassportUpload} required/>
            </div>
            <div className="item-container">
                <label for="IDcard">Upload Work ID card<span className="red-asteriks">*</span></label><br/>
                <input type="file" accept="image/*" name="IDcard" id="IDcard" className="entries" onChange={handleIdImageUpload} required/>
            </div>
            <div className="item-container">
                <label for="payslip">Upload Payslip or 3 Months Bank statement <span className="red-asteriks">*</span></label><br/>
                <input type="file" accept="image/*, .pdf, .doc, .docx, .xls,.xlsx" name="payslip" id="payslip" className="entries" onChange={handlePayslipUpload} required/>
            </div>
            <div className="item-container">
                <label for="letter">Upload Letter of Employment/Confirmation/Promotion.<span className="red-asteriks">*</span></label><br/>
                <input type="file" accept="image/*, .pdf, .doc, .docx, .xls,.xlsx" name="letter" id="letter" className="entries" onChange={handleLetterUpload} required/>
            </div><br/>
            <div className="item-container declaration">
                <input type="checkbox" id="checkboxx"required/>
                <label>
                I hereby declare that all information provided in this application are correct and that all copies of documents I am submitting is authentic. 
                I understand that Unilag Microfinance Bank will verify the information supplied. 
                I therefore agree that any material misstatement/falsification of information discovered renders my application null and void.
                </label>
            </div>
        </div>
    );
}

export default Loandetails;