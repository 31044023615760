import UMFB_Logo from "../UMFB-LOGO-MAIN.png";
import Mainform from "./Mainform";

const Police=()=>{
    var parastatal_form = "NIGERIA POLICE FORCE";
    return(
        <div>
        <center>
        <div className="head-cont">
            <img src={UMFB_Logo} id="UMFB-Logo"/>
            <h2>NIGERIA POLICE FORCE</h2>
        </div>
        <Mainform  {...{parastatal_form}}/>
        </center>
        </div>
    );
}

export default Police;