import UMFB_Logo from "../UMFB-LOGO-MAIN.png";
import NPF_Logo from "../Nigeria_Police_logo.jpg";
import CUSTOMS_Logo from "../customs.jpeg"
import IMMIGRATION_Logo from "../immigration.jpeg"
import { Link } from "react-router-dom";


const Landingpage=()=>{
    return(
        <div align="center">
        <div className="top">
            <img src={UMFB_Logo} id="UMFB-Logo"/>
            <h2>PUBLIC SECTOR ONLINE LOAN APPLICATION FORM</h2>
        </div>
        <div className="parastatals-cont">
            <div className="parastatal-cont" align="center">
                <img src={NPF_Logo} className="p-logo"/>
                <Link to={"/npf"}><button className="btn-landing" onClick={()=>window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}>NIGERIA POLICE FORCE</button></Link>
            </div>
            <div className="parastatal-cont" align="center">
                <img src={IMMIGRATION_Logo} className="p-logo"/>
                <Link to={"/immigration"}><button className="btn-landing" onClick={()=>window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}>NIGERIA IMMIGRATION SERVICE</button></Link>
            </div>
            <div className="parastatal-cont" align="center">
                <img src={CUSTOMS_Logo} className="p-logo"/>
                <Link to={"/customs"}><button className="btn-landing" onClick={()=>window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}>NIGERIA CUSTOMS SERVICE</button></Link>
            </div>
        </div>
        </div>
    );
}

export default Landingpage;