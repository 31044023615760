import './App.css';
import Customs from './components/Customs';
import Police from './components/Police';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Landingpage from './components/Landingpage';
import Immigration from './components/Immigration';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route  path='/' element={<Landingpage/>}/>
          <Route  path='/npf' element={<Police/>}/>
          <Route  path='/immigration' element={<Immigration/>}/>
          <Route  path='/customs' element={<Customs/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
